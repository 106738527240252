import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1> Privacy statement </h1>
    <hr></hr>
    <p>{`Please read this privacy policy carefully before using the mobile application or web application. If you do not agree to the following terms and conditions, do not install the application or use the web application respectively.`}</p>
    <h3> What the privacy policy covers </h3>
    <hr></hr>
    <p>{`This privacy policy covers your use of SOLUS provided web applications in a browser and mobile applications on a mobile device (collectively ‘the application’). The application includes features and content which are offered to you by Solus UK Ltd. The application’s content can include library and service information.`}</p>
    <h3> Personal information </h3>
    <hr></hr>
    <p>{`Personal information is any information that could be used to identify you as an individual.`}</p>
    <p>{`The application can ask you to enter your library user id and personal identifier (PIN or password). This is used to authenticate you against data systems including (but not limited to) Library management systems, PC booking systems, eBook content repositories. The data returned from these systems will tailor the information in the application to your needs, and to define which notifications you will receive and conduct analysis of the application. These details are stored in the secure application storage area on your device.`}</p>
    <p>{`The information will not be stored in a format that could personally identify you by Solus UK Ltd and will only be passed to third parties that are obviously identified within the app (library catalogue, PC Booking system, eBook provider). You can change your details at any time from the Settings menu. You can also choose whether to receive notifications based on the information you have provided in the Notifications are of the Settings menu.`}</p>
    <p>{`SOLUS UK Ltd is committed to protecting your privacy and safety rights while you use the application. Solus UK Ltd enforces strict privacy policies and expects the publishers (Apple, Google, Amazon, Microsoft and RIM) to abide by the following privacy practices.`}</p>
    <h3> No spyware policy </h3>
    <hr></hr>
    <p>{`The application does not collect or transmit personally identifiable information, and does not monitor your personal use of the application.`}</p>
    <p>{`The application sends unidentifiable and non-personal statistical data to help Solus UK Ltd improve the quality of the application, and to improve support processes.`}</p>
    <p>{`The statistical data includes information about:`}</p>
    <ul>
      <li parentName="ul">{`The device you are using (including, for example, the make and model)`}</li>
      <li parentName="ul">{`The carrier or mobile software used`}</li>
      <li parentName="ul">{`General patterns of use (not user specific)`}</li>
      <li parentName="ul">{`Specific events, like software crashes and errors.
When you are on a mobile device, you are at times using a third party to access the internet and/or the application. All requests must be sent through your mobile carrier’s network, and the carrier may have access to it. For information about how the carrier treats your information, you must review your carrier’s privacy policies. Solus UK Ltd has no control over the policies that may be applied by your carrier.`}</li>
    </ul>
    <h3> Third party features and content </h3>
    <hr></hr>
    <p>{`Solus UK Ltd reserves the right to add and/or remove features or content to the application at any time.`}</p>
    <p>{`You are encouraged to read the privacy policies offered by third parties before using any of their content or features. Solus UK Ltd also requires any third parties which may provide you with content, to protect your online privacy rights.`}</p>
    <p>{`In the future, you may choose to add extra content and features to the application through software updates. Solus UK Ltd encourages you to read the privacy policy of any such content before you choose to add it to the application.`}</p>
    <h3> No adware policy </h3>
    <hr></hr>
    <p>{`You will not be required to view obtrusive adverts to use the application.`}</p>
    <h3> Unobtrusive </h3>
    <hr></hr>
    <p>{`The application does not enable other software applications to access data stored on your mobile device.`}</p>
    <p>{`To use this application you will not need to change your mobile device preferences, modify web pages that you visit or accept third party offers. You may voluntarily opt to change or add components, accept notifications or other features from SOLUS UK Ltd.`}</p>
    <h3> Easy uninstall </h3>
    <hr></hr>
    <p>{`Mobile applications users can easily uninstall the mobile application at any time by using the standard uninstall process as may be available as part of your mobile device, or via the mobile application marketplace or network.`}</p>
    <h3> Full control </h3>
    <hr></hr>
    <p>{`You have full control over the application and can modify your preferences at any time. You can deactivate or close the application easily and at any time.`}</p>
    <h3> Report </h3>
    <hr></hr>
    <p>{`If you believe that your rights have been infringed upon while using the application, please email `}<a parentName="p" {...{
        "href": "mailto:support@solus.co.uk."
      }}>{`support@solus.co.uk.`}</a>{` Solus UK Ltd will review your email to ensure that the privacy policies described here have been enforced.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      